import React, { useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import CreatePackage from "./components/CreatePackage";
import Packages from "./components/Packages";
import Login from "./components/Login";
import Register from "./components/Register";
import Header from "./components/Header";
import AuthContext from "./context/AuthContext";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import LoadingContextProvider from "./context/LoadingContext";
import GlobalLoader from "./components/GlobalLoader";
import ColorModeContext from "./context/ColorModeContext";

const App = () => {
  const [mode, setMode] = useState(localStorage.getItem("colorMode") || "dark");
  const [auth, setAuth] = useState(!!localStorage.getItem("access_token"));

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const mode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", mode);
          return mode;
        });
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          background:
            mode === "light"
              ? {
                  default: "#fafafa",
                }
              : {},
          primary: {
            main: "#f44336",
          },
          secondary: {
            main: "#ff9100",
          },
          mode: mode,
        },
        typography: {
          fontFamily: `'Work Sans', sans-serif`,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider
          value={{ auth: auth, setAuth: (val) => setAuth(val) }}
        >
          <LoadingContextProvider>
            <CssBaseline />
            <GlobalLoader />
            <Header />
            <Container fixed sx={{ marginTop: 0, marginBottom: 15 }}>
              <Routes>
                <Route path="/" element={<Navigate to="/packages" replace />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/package/:id" element={<CreatePackage />} />
                <Route path="/package" element={<CreatePackage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
              </Routes>
            </Container>
          </LoadingContextProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
