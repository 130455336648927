import {
  Button,
  Container,
  CssBaseline,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import { useLoadingActionsContext } from "../context/LoadingContext";
import { get, post, put } from "../utils/fetch";
import CustomInput from "./CustomInput";
import { LoadingButton } from "@mui/lab";

const CreatePackage = () => {
  const [name, setName] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const setGlobalLoading = useLoadingActionsContext();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getPackageById = (pkgId) => {
      setGlobalLoading(true);
      get(`/packages/${pkgId}`)
        .then((data) => {
          setName(data.name);
          setTrackingNumber(data.tracking_number);
        })
        .finally(() => setGlobalLoading(false));
    };
    id && getPackageById(id);
  }, [id, setGlobalLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const obj = {
      name,
      tracking_number: trackingNumber,
    };

    if (id) {
      put(`/packages/${id}`, obj).then(() => {
        navigate("/packages");
      });
    } else {
      post(`/packages`, obj)
        .then(() => {
          setName("");
          setTrackingNumber("");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        component={Paper}
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          padding: 3,
        }}
      >
        <Typography variant="h6">{!!id ? "Edit" : "Create"} package</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <CustomInput
            label="Name"
            value={name}
            setValue={setName}
            options={{ required: true }}
          />
          <CustomInput
            label="Tracking number"
            value={trackingNumber}
            setValue={setTrackingNumber}
            options={{ required: true }}
          />
          <Box
            sx={{ mt: 3, mb: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button component={RouterLink} to="/packages">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              {!!id ? "Edit" : "Create"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CreatePackage;
