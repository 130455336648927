import { TextField } from "@mui/material";
import React from "react";

const CustomInput = ({ label, type, value, setValue, options }) => (
  <TextField
    value={value}
    label={label}
    type={type}
    onInput={(e) => setValue(e.target.value)}
    {...options}
    inputProps={options}
    fullWidth
    margin="normal"
  />
);

export default CustomInput;
