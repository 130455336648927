import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const API_URL =
  (process.env.REACT_APP_API_BASE_URL || "http://localhost:8000") + "/api";

const access_token = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
};

const get = (url) => {
  const options = {
    headers: {
      ...access_token(),
    },
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const post = (url, body) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...access_token(),
    },
    body: JSON.stringify(body),
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const put = (url, body) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...access_token(),
    },
    body: JSON.stringify(body),
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const _delete = (url) => {
  const options = {
    method: "DELETE",
    headers: {
      ...access_token(),
    },
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const handleResponse = (res) => {
  return res.text().then((d) => {
    const data = d && JSON.parse(d);
    if (!res.ok) {
      const error = (data && data.message) || res.statusText;

      if (res.status === 401) {
        localStorage.clear();
        history.push("/login");
        window.location.reload();
      }

      return Promise.reject(error);
    }

    return data;
  });
};

export { get, post, put, _delete };
