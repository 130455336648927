import {
  Brightness4,
  Brightness7,
  Logout,
  MenuSharp,
  Person,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ColorModeContext from "../context/ColorModeContext";

const UserMenu = ({ anchorEl, setAnchorEl, onLogout }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(false)}
      onClick={() => setAnchorEl(false)}
      PaperProps={{
        elevation: 0,
        style: {
          width: "100%",
          maxWidth: 180,
        },
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={() => onLogout()}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};

const Header = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [drawer, setDrawer] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    setAuth(false);
    navigate("/login");
  };

  return auth ? (
    <>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          m: 2,
          display: "flex",
          alignItems: "center",
          maxWidth: 400,
        }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <IconButton sx={{ p: "10px" }} onClick={() => setDrawer(true)}>
          <MenuSharp />
        </IconButton>
        <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Track parcel" />
        <IconButton type="submit" sx={{ p: "10px" }}>
          <Search />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: "primary.main" }}>
            <Person />
          </Avatar>
        </IconButton>
      </Paper>
      <UserMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onLogout={handleLogout}
      />
      <Drawer
        anchor="left"
        open={drawer}
        onClose={() => setDrawer(false)}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Settings
          </Typography>
        </Toolbar>
        <Divider />
        <List>
          <ListItem button onClick={colorMode.toggleColorMode}>
            <ListItemIcon>
              {theme.palette.mode === "dark" ? (
                <Brightness7 />
              ) : (
                <Brightness4 />
              )}
            </ListItemIcon>
            <ListItemText>{theme.palette.mode} mode</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  ) : null;
};

export default Header;
