import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext();
const LoadingActionsContext = createContext();

export const useLoadingContext = () => useContext(LoadingContext);
export const useLoadingActionsContext = () => useContext(LoadingActionsContext);

const LoadingContextProvider = (props) => {
  const [globalLoading, setGlobalLoading] = useState(false);

  return (
    <LoadingContext.Provider value={globalLoading}>
      <LoadingActionsContext.Provider value={setGlobalLoading}>
        {props.children}
      </LoadingActionsContext.Provider>
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
