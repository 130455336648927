import { LinearProgress } from "@mui/material";
import React from "react";
import { useLoadingContext } from "../context/LoadingContext";

const GlobalLoader = () => {
  const globalLoading = useLoadingContext();

  return globalLoading ? (
    <LinearProgress sx={{ position: "fixed", top: 0, left: 0, right: 0 }} />
  ) : null;
};

export default GlobalLoader;
