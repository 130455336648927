import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { get, _delete } from "../utils/fetch";

import { LoadingButton } from "@mui/lab";
import {
  Card,
  Button,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Alert,
  Collapse,
} from "@mui/material";
import {
  ArrowForward,
  ContentCopy,
  DeleteForever,
  ExpandMore as ExpandMoreIcon,
  MoreVert,
} from "@mui/icons-material";
import { styled } from "@mui/system";

const SettingsMenu = ({ anchorEl, setAnchorEl, onCopy, onDelete }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(false)}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      PaperProps={{
        style: {
          width: 180,
          maxWidth: "100%",
        },
      }}
    >
      <MenuItem
        onClick={() => {
          onCopy();
          setAnchorEl(false);
        }}
        disableRipple
      >
        <ListItemIcon>
          <ContentCopy fontSize="small" />
        </ListItemIcon>
        <ListItemText>Copy</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          onDelete();
          setAnchorEl(false);
        }}
        disableRipple
      >
        <ListItemIcon>
          <DeleteForever fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Package = (props) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleTrack = (id) => {
    setLoading(true);
    get(`/packages/${id}/track`)
      .then((data) => setDetails(data.sort(comparator)))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  };

  const comparator = (a, b) => {
    const dateB = new Date(Date.parse(b.date.replaceAll("-", " ")));
    const dateA = new Date(Date.parse(a.date.replaceAll("-", " ")));
    return dateB - dateA;
  };

  const handleDelete = (id) => {
    _delete(`/packages/${id}`).then(() => props.onDelete());
  };

  return (
    <>
      <Card>
        <CardHeader
          titleTypographyProps={{ component: "div", variant: "h6" }}
          subheaderTypographyProps={{
            variant: "body2",
            color: "text.secondary",
          }}
          title={props.name}
          subheader={props.tracking_number}
          action={
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreVert />
            </IconButton>
          }
        ></CardHeader>

        <CardContent sx={{ padding: 0 }}>
          {error && <Alert severity="error">{error}</Alert>}
          {details && details.length === 0 && (
            <Alert sx={{ borderRadius: 0 }} severity="info">
              Package is still in transit!
            </Alert>
          )}
          {details && details.length > 0 && (
            <Collapse collapsedSize={80} in={expanded}>
              <List disablePadding>
                {details.map((pd, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variant="subtitle2"
                            sx={{ display: "flex" }}
                          >
                            {pd.begin} <ArrowForward fontSize="small" />{" "}
                            {pd.end}
                          </Typography>
                        </>
                      }
                      primaryTypographyProps={{ variant: "subtitle2" }}
                      secondary={
                        <Typography variant="caption">
                          {pd.note} <br /> {pd.date}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          {details && details.length > 1 && (
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              color="primary"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
          <Button component={RouterLink} to={`/package/${props.id}`}>
            Edit
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => handleTrack(props.id)}
            loading={loading}
          >
            Track
          </LoadingButton>
        </CardActions>
      </Card>
      <SettingsMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onCopy={() => navigator.clipboard.writeText(props.tracking_number)}
        onDelete={() => handleDelete(props.id)}
      />
    </>
  );
};

export default Package;
