import { Add } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "../utils/fetch";
import Package from "./Package";
import { useLoadingActionsContext } from "../context/LoadingContext";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const setGlobalLoading = useLoadingActionsContext();

  const fetchPackages = useCallback(() => {
    setGlobalLoading(true);
    get(`/packages`)
      .then((data) => {
        setPackages(data);
      })
      .finally(() => setGlobalLoading(false));
  }, [setGlobalLoading]);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  return (
    <>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        {packages.map((p) => (
          <Grid item xs={4} sm={4} md={4} key={p.id}>
            <Package {...p} onDelete={() => fetchPackages()} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ position: "fixed", bottom: 30, right: 16 }}>
        <Fab
          color="primary"
          aria-label="add"
          variant="extended"
          LinkComponent={RouterLink}
          to="/package"
        >
          <Add sx={{ mr: 1 }} />
          Create
        </Fab>
      </Box>
    </>
  );
};

export default Packages;
