import { LockOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Container,
  Grid,
  Paper,
  Typography,
  Link,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import { post } from "../utils/fetch";
import CustomInput from "./CustomInput";
import { useNavigate, Link as RouterLink } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    post(`/auth/login`, { username, password })
      .then(({ token }) => {
        localStorage.setItem("access_token", token);
        setAuth(true);
        navigate("/packages");
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component={Paper}
        padding={3}
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {error && <Alert severity="error">{error}</Alert>}
          <CustomInput
            label="Username"
            value={username}
            setValue={setUsername}
            options={{ required: true }}
          />
          <CustomInput
            type="password"
            label="Password"
            value={password}
            setValue={setPassword}
            options={{ required: true, minLength: 6 }}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Sign In
          </LoadingButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} to="/register" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
